
import { Component, Ref, Vue, Prop } from "vue-property-decorator";
import Notify from "@/utils/notifications";
import Card from "@/models/Card";
import * as CardServices from "@/api/helpers/Card";
import CardForm from "@/components/CardForm.vue";
import RuleForm from "@/components/RuleForm.vue";
import CardTemplate from "@/components/CardTemplate.vue";
import ConfigForm from "@/components/CardConfigForm.vue";
import * as CardSettingServices from "@/api/helpers/CardSetting";
import * as ColumnServices from "@/api/helpers/Column";
import * as SystemServices from "@/api/helpers/System";
import Column from "@/models/Column";
import _ from "underscore";
import { Count } from "@/api/Gondor";
import { KeySystem } from "@/api/helpers/KeySystem";

interface CardSetting {
  id: number;
  value: string;
  cardId: number;
  keySystemId: number;
  name: string; //KeySystem name
}

@Component({
  components: {
    CardForm,
    RuleForm,
    CardTemplate,
    ConfigForm,
  },
})
export default class CardWizard extends Vue {
  @Ref() readonly cardForm!: CardForm;

  systemId = +this.$route.params.system || +sessionStorage.getItem("systemId")!;
  fullscreenLoading = false;
  loading = false;
  currentStep = 0;
  lastStep = 3;
  card = _.extend(new Card(), {
    showImprovement: this.$route.params.showImprovement,
  });
  columns: Array<Column> = [];
  ruleForm!: HTMLFormElement;

  // settings: Setting[] = [];
  cardSettings: CardSetting[] = [];
  configLoading = false;
  keys: Count<KeySystem> = {
    count: 0,
    rows: [],
  };

  newSetting(setting: Omit<CardSetting, "id">) {
    this.cardSettings.push({ ...setting, id: this.cardSettings.length });
  }

  deleteSetting(id: number) {
    this.cardSettings.splice(id, 1);
  }

  nextStep() {
    switch (this.currentStep) {
      case 0:
        this.validateCardForm();
        break;
      case 1:
        if (this.card.showImprovement) {
          this.currentStep += 1;
        } else {
          this.currentStep += 2;
        }
        break;
      case 2:
        this.currentStep += 1;
        break;
    }
  }

  validateCardForm() {
    const cardForm = this.cardForm.validateForm();

    if (!cardForm) {
      return false;
    } else {
      this.currentStep += 1;
    }
  }

  getColumns() {
    ColumnServices.find({
      order: "name",
      system: this.$route.params.system,
    })
      .then(res => {
        this.columns = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  async createCard() {
    this.loading = true;
    this.ruleForm = this.$refs["ruleForm"] as HTMLFormElement;
    this.ruleForm.clearRules();
    const cardCopy = { ...this.card };
    this.loading = true;
    if (
      typeof this.card.activeDays === "undefined" ||
      (Array.isArray(this.card.activeDays) && this.card.activeDays.length === 0)
    ) {
      cardCopy.startTimeActivity = null;
      cardCopy.endTimeActivity = null;
    }
    if (this.cardForm.imageFile().has("file")) {
      const imageUrl = await CardServices.uploadImage(
        this.cardForm.imageFile()
      );
      cardCopy.b64Picture = imageUrl.data.data.url;
      this.cardForm.clearFormData();
    }

    CardServices.create(cardCopy)
      .then(res => {
        const requestsConfig = this.cardSettings.map(setting => {
          const { keySystemId, value } = setting;
          return CardSettingServices.create({
            value,
            keySystemId,
            cardId: res.data.data.id,
          });
        });
        Promise.all(requestsConfig);
        Notify.successful("Tarjeta creada exitosamente.");
        this.$router.push("/card");
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  changedUrl(url: string) {
    this.card.urls = [url];
  }

  cancel() {
    this.$router.push("/card");
  }

  getKeys() {
    SystemServices.findKeys(this.systemId, {
      order: "name:asc",
      active: 1,
    })
      .then(res => {
        this.keys = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  created() {
    const title = "Nueva tarjeta";
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: "Tarjetas",
        to: {
          name: "card",
        },
      },
      {
        text: title,
      },
    ]);
    this.card.rules = [];
    this.card.systemId = this.systemId;
    if (!this.card.systemId) {
      this.$router.push("/card");
    }
    this.getColumns();
    this.getKeys();
  }
}
